export function isMobileDevice() {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;

    return regexp.test(details);
}

export function isAndroid() {
    let details = navigator.userAgent;
    let regexp = /android/i;

    return regexp.test(details);
}
